import { useEffect, useState } from 'react'
import type { useInfiniteHits } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTrackSearchEvents } from 'src/hooks/analytics/useTrackSearchEvents'

import {
  getSelectedAssetIds,
  toggleSelectedAsset,
  useCartContext,
} from '@/contexts/CartContext'
import { useAnalyticsTracking } from '@/hooks'
import type { AssetModel } from '@/types'

import { createDownloadCellRendererComponent } from './cellRenderers/DownloadCellRenderer'
import { FileSizeCellRenderer } from './cellRenderers/FileSizeCellRenderer'
import { FileTypeCellRenderer } from './cellRenderers/FileTypeCellRenderer'
import { LanguageCellRenderer } from './cellRenderers/LanguageCellRenderer'
import { LastUpdatedCellRenderer } from './cellRenderers/LastUpdatedCellRenderer'
import { NameCellRenderer } from './cellRenderers/NameCellRenderer'
import { PageCountCellRenderer } from './cellRenderers/PageCountCellRenderer'
import { RowSelectionCellRenderer } from './cellRenderers/RowSelectionCellRenderer'

type ActualHitsProps = {
  items: ReturnType<typeof useInfiniteHits<AssetModel>>['items']
  results: ReturnType<typeof useInfiniteHits<AssetModel>>['results']
  sendAlgoliaEvent: ReturnType<typeof useInfiniteHits<AssetModel>>['sendEvent']
}

export const SearchResultsTable = ({ items, results, sendAlgoliaEvent }: ActualHitsProps) => {
  const { state, dispatch } = useCartContext()

  const { trackDownload } = useAnalyticsTracking()

  const DownloadCellRenderer = createDownloadCellRendererComponent(
    sendAlgoliaEvent,
    trackDownload,
  )

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.hitsDataGrid',
  })

  // eslint-disable-next-line no-underscore-dangle
  useTrackSearchEvents(results?._state.query, Boolean(results?.nbHits && results.nbHits > 0))

  const [selectedAssetIds, setSelectedAssetIds] = useState<Set<string>>(new Set())
  useEffect(() => {
    setSelectedAssetIds(new Set(getSelectedAssetIds(state)))
  }, [state])

  const CustomRowSelectionCellRenderer = RowSelectionCellRenderer(selectedAssetIds)

  return (
    <TableContainer
      sx={{
        boxShadow: 'none',
        border: 'solid',
        margin: 0,
        marginBottom: 3,
        borderWidth: '1px 0', // top and bottom
        borderColor: (theme) => theme.palette.divider,
      }}
      data-cy='data-cy="DownloadDocuments-ResultsTable'
    >
      <Table>
        <TableBody>
          {items.map((row) => {
            return (
              <TableRow
                key={row.assetId}
                sx={{
                  cursor: 'pointer',
                  '& > *': {
                    width: 'auto !important',
                  },
                }}
                onClick={() => {
                  if (selectedAssetIds.size >= 10 && !selectedAssetIds.has(row.assetId)) {
                    // too many item in the cart already
                    return enqueueSnackbar(t('snackbarMessage'), {
                      variant: 'info',
                      preventDuplicate: true,
                    })
                  }

                  dispatch(
                    toggleSelectedAsset({
                      ...row,
                      selectedAt: new Date().toISOString(),
                    }),
                  )
                }}
                data-cy={`DownloadDocument-GridItem-${row.assetId}`}
              >
                <TableCell
                  align="center"
                  sx={{
                    width: { xs: '50px !important', sm: '58px !important' },
                    px: { xs: '0px !important', sm: '8px !important' },
                  }}
                >
                  <CustomRowSelectionCellRenderer row={row} />
                </TableCell>
                <TableCell sx={{ paddingLeft: '8px !important', flex: 1 }}>
                  <NameCellRenderer row={row} />
                </TableCell>
                <TableCell sx={{ width: '100px', display: { xs: 'none', md: 'table-cell' } }}>
                  <LastUpdatedCellRenderer row={row} />
                </TableCell>
                <TableCell sx={{ width: '60px', display: { xs: 'none', md: 'table-cell' } }}>
                  <FileTypeCellRenderer row={row} />
                </TableCell>
                <TableCell sx={{ width: '85px', display: { xs: 'none', md: 'table-cell' } }}>
                  <FileSizeCellRenderer row={row} />
                </TableCell>
                <TableCell
                  sx={{
                    width: '100px',
                    textWrap: 'nowrap',
                    display: { xs: 'none', md: 'table-cell' },
                  }}
                >
                  <PageCountCellRenderer row={row} />
                </TableCell>
                <TableCell sx={{ width: '50px', display: { xs: 'none', md: 'table-cell' } }}>
                  <LanguageCellRenderer row={row} />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    display: { xs: 'none', sm: 'table-cell' },
                    width: '60px !important',
                    px: '8px !important',
                  }}
                >
                  <DownloadCellRenderer row={row} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
