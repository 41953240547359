import { Typography } from '@mui/material'
import type { Hit } from 'instantsearch.js'

import type { AssetModel } from '@/types'

export const FileTypeCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { fileFormat } = row

  if (!fileFormat) return null

  return (
    <Typography variant="body3dense" textTransform="uppercase">
      {fileFormat}
    </Typography>
  )
}
