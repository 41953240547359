import type { Hit } from 'instantsearch.js'

import type { AssetModel, SelectedAsset } from '@/types'

export const reduceAssetModel = (assetModel: Hit<AssetModel>): SelectedAsset => {
  return {
    ...assetModel,
    selectedAt: '',
  }
}
