import { useEffect } from 'react'

import debounce from 'lodash/debounce'

import { defaultValues, useGetCustomPropertiesFromAlgolia } from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

const debounceTrackSearchEvent = debounce(
  (filterSearchTerm, filterStatus, customProperties, analytics) => {
    const searchProperties = {
      ...customProperties,
      filter_status: filterStatus ? 'true' : 'false',
      filter_search_term: filterSearchTerm ?? '',
    }

    analytics.track('internal_search', {
      ...defaultValues,
      ...searchProperties,
    })
  },
  2000,
)

export const useTrackSearchEvents = (searchTerm: string | undefined, hasResults: boolean) => {
  const { analytics, isReady } = useInitializeAnalytics()
  const customProps = useGetCustomPropertiesFromAlgolia()

  useEffect(() => {
    if (isReady) {
      debounceTrackSearchEvent(searchTerm, hasResults, customProps, analytics)
    }
  }, [searchTerm, hasResults])
}
