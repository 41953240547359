import { Typography } from '@mui/material'
import type { Hit } from 'instantsearch.js'

import type { AssetModel } from '@/types'

export const LanguageCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { language } = row

  if (!language) return null

  return (
    <Typography variant="body3dense" textTransform="uppercase">
      {language}
    </Typography>
  )
}
