import { useRouter } from 'next/router'

import { Typography } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import prettyBytes from 'pretty-bytes'

import type { AssetModel } from '@/types'

export const FileSizeCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { fileSize } = row

  const { locale } = useRouter()

  if (!fileSize) return null

  return (
    <Typography variant="body3dense">
      {prettyBytes(parseInt(fileSize, 10), { locale })}
    </Typography>
  )
}
