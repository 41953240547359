import { useTranslation } from 'next-i18next'

import { Typography } from '@mui/material'
import type { Hit } from 'instantsearch.js'

import type { AssetModel } from '@/types'

export const PageCountCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { pageCount: count } = row

  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.hitsDataGrid',
  })

  if (!count) return null

  return (
    <Typography variant="body3dense">
      {/* TODO why cannot i18next figure out the correct key for pl-pl? */}
      {t(count === 1 ? 'page_one' : 'page_other', { count })}
    </Typography>
  )
}
