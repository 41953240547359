import { useRouter } from 'next/router'

import { Typography } from '@mui/material'
import type { Hit } from 'instantsearch.js'

import type { AssetModel } from '@/types'

import { formatDate } from './NameCellRenderer'

export const LastUpdatedCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { lastUpdated } = row

  const { locale } = useRouter()

  if (!lastUpdated) return null

  return <Typography variant="body3dense">{formatDate(lastUpdated, locale)}</Typography>
}
