import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { DEFAULT_CROWDIN_LOCALE } from '@knauf-group/ct-shared-nextjs/utils/constants'
import type { Theme } from '@mui/material'
import { Stack, Typography, useMediaQuery } from '@mui/material'
import type { Hit } from 'instantsearch.js'
import prettyBytes from 'pretty-bytes'
import { generateLineClamp } from 'src/utils/frontend/generateLineClamp'

import type { AssetModel } from '@/types'

const styles = () => {
  return {
    container: {
      paddingRight: 2,
    },
    textStyle: {
      color: 'text.primary',
      maxWidth: '650px',
      whiteSpace: 'break-spaces',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'anywhere',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: { xs: 3, lg: 2 },
    },
  }
}

export const formatDate = (unixTime: string, locale: string = DEFAULT_CROWDIN_LOCALE) => {
  const date = new Date(parseInt(unixTime, 10))

  return date.toLocaleDateString(locale, { month: 'short', year: 'numeric' })
}

type InfoTextProps = {
  text: any
  dataCy?: string
  textTransform?: Parameters<typeof Typography>[0]['textTransform']
}

const InfoText = ({ text, dataCy, textTransform = 'none' }: InfoTextProps) => {
  return (
    <Typography
      variant="body3"
      color="text.secondary"
      textTransform={textTransform}
      data-cy={dataCy}
    >
      {text}
    </Typography>
  )
}

const formatDocumentType = (documentTypes: string[], t: any) => {
  return t(documentTypes[0]) || ''
}

export const NameCellRenderer = ({ row }: { row: Hit<AssetModel> }) => {
  const { documentTypes, name, fileFormat, fileSize, pageCount, language, lastUpdated } = row
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.searchAndHitsPanel' })
  const { t: tDA } = useTranslation('dam-assets', { keyPrefix: 'assetCategories' })
  const { locale } = useRouter()

  const isNonDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  return (
    <Stack sx={styles().container}>
      <Typography
        variant="body2"
        sx={styles().textStyle}
        data-cy={`DownloadDocument-TableItem-title-${row.assetId}`}
      >
        {name.trim()}
      </Typography>
      <Stack direction="row" spacing={1} sx={generateLineClamp(1)}>
        <InfoText text={formatDocumentType(documentTypes, tDA)} />
      </Stack>
      {isNonDesktop && (
        <Stack direction="row" spacing={2}>
          {lastUpdated && (
            <InfoText
              text={formatDate(lastUpdated, locale)}
              dataCy={`DownloadDocument-TableItem-LastUpdatedDate-${row.assetId}`}
            />
          )}
          {!!fileFormat && (
            <InfoText
              text={fileFormat}
              textTransform="uppercase"
              dataCy={`DownloadDocument-TableItem-FileFormat-${row.assetId}`}
            />
          )}
          {!!fileSize && (
            <InfoText
              text={prettyBytes(parseInt(fileSize, 10), { locale })}
              dataCy={`DownloadDocument-TableItem-FileSize-${row.assetId}`}
            />
          )}
          {!!pageCount && (
            <InfoText
              // TODO why cannot i18next figure out the correct key for pl-pl?
              text={t(pageCount === 1 ? 'hitsDataGrid.page_one' : 'hitsDataGrid.page_other', {
                count: pageCount,
              })}
              dataCy={`DownloadDocument-TableItem-NumPages-${row.assetId}`}
            />
          )}
          {!!language && (
            <InfoText
              text={language}
              textTransform="uppercase"
              dataCy={`DownloadDocument-TableItem-Language-${row.assetId}`}
            />
          )}
        </Stack>
      )}
    </Stack>
  )
}
