import { useTranslation } from 'next-i18next'

import Checkbox from '@mui/material/Checkbox'
import type { Hit } from 'instantsearch.js'

import type { AssetModel } from '@/types'

export const RowSelectionCellRenderer =
  (selectedAssetIds: Set<string>) =>
  ({ row }: { row: Hit<AssetModel> }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation('download-center', {
      keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
    })

    const checked = selectedAssetIds.has(row.assetId)

    const checkboxLabelID = `asset-selection-checkbox-label-${row.assetId}`
    const a11yLabel = `${t(checked ? 'unselect_asset' : 'select_asset')}: ${row.name}`

    return (
      <>
        {/* Cannot directly add `aria-label` to HTML-native with MUI somehow, this is a workaround */}
        {/* eslint-disable jsx-a11y/label-has-associated-control */}
        <label id={checkboxLabelID} style={{ display: 'none' }}>
          {a11yLabel}
        </label>
        <Checkbox
          color="secondary"
          checked={checked}
          data-cy={`DownloadDocument-TableItem-checkbox-${row.assetId}`}
          inputProps={{
            'aria-label': a11yLabel,
            'aria-labelledby': checkboxLabelID,
          }}
        />
      </>
    )
  }
