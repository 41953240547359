import type { Theme } from '@mui/material/styles'
import type { SystemStyleObject } from '@mui/system'

export const generateLineClamp = (lines: number): SystemStyleObject<Theme> => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflowWrap: 'anywhere',
  WebkitLineClamp: lines,
})
